<template>
  <div id="CriteriaRegister">
    <button
      @click="addCriteria()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Agregar criterio de calificación
    </button>
    <div>
      <b-modal
        scrollable
        centered
        v-model="show"
        title="Registra Criterio de calificación"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label><b>Nombre de criterio:</b></label>
                  <b-form-input
                    type="text"
                    :state="dataSend.name != null"
                    v-model="dataSend.name"
                    placeholder="Ingresar nombre"
                    required
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label><b>Porcentaje:</b></label>
                  <b-input-group prepend="%" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input
                      type="number"
                      :state="
                        dataSend.porcentual != null &&
                        verifyPorcentual(dataSend.porcentual)
                      "
                      v-model="dataSend.porcentual"
                      placeholder="Ingresar porcentaje"
                      required
                    />
                  </b-input-group>
                </div>
              </div>
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AlertError from "../../components/AlertError.vue";
export default {
  name: "CriteriaRegister",
  components: { AlertError },
  props: {
    idCourse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      dataSend: {},
    };
  },
  methods: {
    verifyPorcentual(data) {
      if (data < 0 || data > 100) return false;
      else return true;
    },
    async saveAddItem() {
      if (this.dataSend.name == null || this.dataSend.porcentual == null) {
        this.$refs.error.showAlert("Ingrese todos los datos");
        return;
      }
      if (!this.verifyPorcentual(this.dataSend.porcentual)) {
        this.$refs.error.showAlert("Fuera de los margenes porcentuales");
        return;
      }
      //console.log(JSON.stringify(this.dataSend, undefined, "\t"));
      await this.$axios
        .post(
          "/academic/course/" +
            this.idCourse +
            "/qualification-criteria-register",
          this.dataSend,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.showAlert(e.response.data.content);
        });
    },
    addCriteria() {
      this.show = true;
      this.dataSend = {};
    },
    exit() {
      this.show = false;
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>