<template>
  <div id="CoursesCriteria">
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h1>
                <b-icon icon="check-square" /> Criterios de calificación de
                {{ desserts.name }}
              </h1>
            </div>
            <div class="col-1">
              <h1>
                <b-icon
                  type="button"
                  @click="toCourse"
                  icon="arrow-return-left"
                />
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col><CriteriaRegister :idCourse="id" /></b-col>
            <b-col> </b-col>
          </b-row>
          <div v-if="desserts.list.length != 0">
            <b-table
              striped
              responsive
              stacked="lg"
              :items="desserts.list"
              :fields="fields"
            >
              <template #cell(actions)="row">
                <b-icon-trash
                  type="button"
                  size="sm"
                  class="mx-1"
                  @click="modalDelete(row.item)"
                ></b-icon-trash>
              </template>
            </b-table>
          </div>

          <div v-else>
            <hr />
            <em><p class="text-center">No hay datos en esta tabla</p></em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CriteriaRegister from "./Courses-criteria-register.vue";
export default {
  name: "CoursesCriteria",
  components: { CriteriaRegister },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      desserts: { list: [] },
      fields: [
        { key: "name", label: "Nombre" },
        { key: "porcentual", label: "Porcentaje" },
      ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    toCourse() {
      this.$router.go(-1);
    },
    async initialize() {
      await this.$axios
        .get("/academic/course/" + this.id + "/qualification-criteria", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>